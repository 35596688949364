import axios from 'axios';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

/**
 *
 * @param { string } string
 * @param { string } replaceSpaceWith
 * @returns returns given string with spaces replaced by whats passed in replaceSpaceWith
 */
export const toLowercaseAndReplaceSpaces = (
  string,
  replaceSpaceWith = '',
) => string.replace(/ /g, replaceSpaceWith).toLowerCase();

/**
 *
 * @param { string } type
 * @param { dispatch } dispatch
 * @param { any } payload
 * @returns object with type and payload
 */
export const dispatcher = (type, dispatch, payload) => {
  return dispatch({
    type,
    payload,
  });
};

/**
 *
 * @param { component } LoadingComponent
 * @param { component } ErrorComponent
 * @param { component } DataComponent
 * @param { string } queryStatus
 * @param { boolean } isLoading
 * @param { boolean } isError
 * @param { boolean } isSuccess
 * @param { boolean } isFetching
 * @param { component } FetchingComponent
 * @returns corresponding component passed
 */
export const reactQueryLoadingHelper = (
  LoadingComponent = '',
  ErrorComponent = '',
  DataComponent,
  queryStatus,
  isLoading,
  isError,
  isSuccess,
  isFetching = false,
  FetchingComponent = '',
) => {
  const isComponentLoading = queryStatus === 'loading' || isLoading;
  const isComponentError = queryStatus === 'error' || isError;
  return (
    <>
      {isFetching && FetchingComponent}
      {isComponentLoading
        ? LoadingComponent
        : isComponentError
          ? ErrorComponent
          : DataComponent}
    </>
  );
};

/**
 *
 * @param { date time object } date
 * @param { date time object } time
 * @param { boolean  } combine
 * @returns combined single date of date and time given, and convert it to ISO8601 format
 */
export const dateConverter = (date, time = null, combine = false) => {
  if (combine) {
    const dayJsDate = dayjs(date);
    const dayJsTime = dayjs(time);
    const combinedDateAndTime = dayJsDate
      .hour(dayJsTime.hour())
      .minute(dayJsTime.minute())
      .second(dayJsTime.second());
    return {
      rawValue: combinedDateAndTime,
      convertedValue: combinedDateAndTime?.toISOString(),
    };
  } else {
    const dayJsDate = dayjs(date);
    return {
      rawValue: dayJsDate,
      convertedValue: dayJsDate?.toISOString(),
    };
  }
};

/**
 *
 * @param { array } numbers
 * @param { number } reference
 * @returns array of ratios for a given number
 */
export function getRatioForNumber(numbers, reference) {
  const ratios = numbers.map((item) => {
    return {
      ...item,
      ratioCalculated: item.value / reference,
    };
  });
  return ratios;
}

/**
 *
 * @param { number } reference
 * @param { array } ratios
 * @returns array of rations calculated based on ratios passed for the given number
 */
export function generateRatioForNumber(reference, ratios) {
  const numbers = ratios.map((ratio) => {
    return {
      label: ratio.label,
      convertedValue: reference * ratio.ratioCalculated,
    };
  });
  return numbers;
}

/**
 *
 * @param { string } permissionCategoryName
 * @param { string } permissionName
 * @param { object } fullPermissionData
 * @returns boolean, true if user has permission
 */
export const checkPermissions = (
  permissionName = null,
  fullPermissionData,
) => {
  let hasPermission = false;
  // if inner permission name is passed permission boolean works based on weather its true or not
  if (permissionName) {
    hasPermission = fullPermissionData?.[permissionName];
  }
  return hasPermission;
};

/**
 *
 * @param { number} percentageToFind
 * @param { number } Number
 * @returns percentage of a number
 */
export const getPercentageOfaNumber = (
  percentageToFind = 0,
  Number = 0,
) => {
  return (parseFloat(percentageToFind) / 100) * parseFloat(Number);
};

/**
 *
 * @param { number } interviewHoursInMinutes
 * @param { number } companyStartTimeInRailwayHours
 * @param { number } companyEndTimeInRailwayHours
 * @returns slot array dynamically generated based on interview hours, eg 1 for starting and 24 for ending
 */
const GENERATE_TIME_SLOTS_CONFIG = {
  labelStartFormat: 'hh:mm A',
  labelEndFormat: 'hh:mm A',
};
export const generateTimeSlots = (
  interviewHoursInMinutes,
  companyStartTimingData,
  companyEndTimingData,
  config = GENERATE_TIME_SLOTS_CONFIG,
) => {
  console.log('test', {
    interviewHoursInMinutes,
    companyStartTimingData,
    companyEndTimingData,
    config,
  });
  const { labelStartFormat, labelEndFormat } = config;
  const startDate = dayjs(new Date())
    .set('hour', companyStartTimingData.hour)
    .set('minute', companyStartTimingData.minute)
    .set('second', companyStartTimingData.second)
    .set('millisecond', 0);
  const endDate = dayjs(new Date())
    .set('hour', companyEndTimingData.hour)
    .set('minute', companyEndTimingData.minute)
    .set('second', companyEndTimingData.second)
    .set('millisecond', 0);
  let endDateToShow = endDate;
  let startDateToShow = startDate;
  const companyWorkingHoursInMinutes = endDate.diff(startDate, 'minutes');
  const numberOfSlotsAsPerWorkingHours =
    companyWorkingHoursInMinutes / interviewHoursInMinutes;
  const slots = [];
  for (let i = 1; i <= numberOfSlotsAsPerWorkingHours; i++) {
    endDateToShow = startDateToShow.add(interviewHoursInMinutes, 'minute');
    const labelStart = dateFormatter(startDateToShow, labelStartFormat);
    const labelEnd = dateFormatter(endDateToShow, labelEndFormat);
    const label = `${labelStart} - ${labelEnd}`;
    const value = `${i}${label}`;
    slots.push({
      label: label,
      startDate: startDateToShow,
      endDate: endDateToShow,
      value: value,
    });
    startDateToShow = endDateToShow;
  }
  return slots;
};

/**
 *
 * @returns previous hours and minutes as an array from current time
 */
export const getDisabledHoursAndMinutes = (
  provideHours = true,
  provideMinutes = true,
) => {
  const currentHour = dayjs().hour();
  const currentMinute = dayjs().minute();
  return {
    ...(provideHours && {
      disabledHours: () =>
        Array.from({ length: currentHour }, (v, k) => k),
    }),
    ...(provideMinutes && {
      disabledMinutes: () =>
        Array.from({ length: currentMinute }, (v, k) => k),
    }),
  };
};

/**
 * @param { all types that dayjs function accepts } date
 * @returns returns start and end of a date , start will be 12 am and end will be 11:59:59 of that date
 */
export const getFullDayAsaRange = (date) => {
  const start = dayjs(date)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);
  const end = dayjs(date)
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 0);
  return {
    start,
    end,
  };
};

/**
 * @param { number } page - current selected page number.
 * @param { number } totalItems - likely to be the "count" field in the List API payload.
 * @param { number } pageSize - used to slice the totalItems field. Likely mapped to the "limit" param.
 */
export const calculatePaginationData = (page, totalItems, pageSize) => {
  const start = page * pageSize + 1;
  const end =
    (page + 1) * pageSize < totalItems
      ? (page + 1) * pageSize
      : totalItems;
  // offset is 0 for first element
  const offset = page * pageSize;
  return {
    page,
    pageSize,
    offset,
    start,
    end,
  };
  // "page": 0,
  // "pageSize": 5,
  // "offset": 0
};

/**
 *
 * @param { all type dayjs accepts } date
 * @param { string } formatToConvertTo
 * @returns
 */
export const dateFormatter = (date, formatToConvertTo) => {
  const dayJsDate = dayjs(date);
  return dayJsDate.format(formatToConvertTo);
};

/**
 *
 * @param { string } url
 * @param { string } filename - name to override the file
 * @returns
 */
export const createDownloadLink = (url, filename, inNewTab = false) => {
  const link = document.createElement('a');
  link.href = url;
  if (inNewTab) {
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
  }
  if (filename) {
    link.download = filename;
  }
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

/**
 * Function checks the positioning of a strings' letter. 0 means letters in the same place regardless of case and accent.
 *
 * @param { string } first
 * @param { string } second - name to override the file
 * @returns boolean if letters are matching
 */
export const isCaseInsensitiveEqual = (first, second) =>
  first.localeCompare(second, undefined, { sensitivity: 'base' }) === 0;

export const timeConvert = (time) => {
  const dayJsTime = dayjs(time);
  const oTime = dayJsTime.format('HH:mm:ss');
  return {
    rawValue: time,
    convertedValue: oTime,
  };
};

// userPreference getLocal Storage Item.

export const getUserPreference = () => {
  let getPreferences = JSON.parse(localStorage.getItem('userPreference'));
  return getPreferences;
};

export const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const getFileType = (filepath) => {
  let type = '';
  if (filepath) {
    const file = filepath?.split('.');
    type = file[file.length - 1];
  }
  return type;
};

export function mandatoryFieldsMessage(fieldsObject) {
  if(fieldsObject){
    const keys = Object.keys(fieldsObject);
    if (keys.length === 0) return ''; // If no keys, return an empty string
  
    // Capitalize the first letter of each key and join them with commas and 'and'
    const formattedKeys = keys.map(
      (key) => key.charAt(0).toUpperCase() + key.slice(1),
    );
    const lastKey = formattedKeys.pop();
  
    if (formattedKeys.length === 0) {
      return `${lastKey} is a mandatory field`;
    }
  
    const keysString = formattedKeys.join(', ') + ` and ${lastKey}`;
    return `${keysString} are mandatory fields`;
  }
}

export const getOS = () => {
  let userAgent = window.navigator.userAgent;
  let platform = window.navigator.platform;
  let os = null;

  if (platform.startsWith('Win')) {
    os = 'Windows';
  } else if (platform.startsWith('Mac')) {
    os = 'macOS';
  } else if (platform.startsWith('Linux')) {
    os = 'Linux';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    os = 'iOS';
  } else {
    os = 'Unknown';
  }

  return os;
};

export function replacePlaceholders(template, data) {
  return template.replace(/{(\w+)}/g, (_, key) => data[key] || '');
}

export function extractPlaceholders(template) {
  let placeholders = [];
  const regex = /{(\w+)}/g;
  let match;

  while ((match = regex.exec(template)) !== null) {
    placeholders.push(match[1]);
  }
  if (placeholders?.length) {
    placeholders = [...new Set(placeholders)]; //remove duplications
  }

  return placeholders;
}

export const getSamplePreviewData = (field) => {
  let sampleData = '';
  if (field === 'name') {
    sampleData = 'Alex Smith';
  } else if (field === 'vacancy' || field === 'job') {
    sampleData = 'Java Developer';
  } else if (field === 'company') {
    sampleData = 'ABC Company Pvt Lmtd';
  } else if (field === 'start_date' || field === 'date') {
    const today = dayjs().format('DD/MM/YYYY');
    sampleData = today;
  } else if (field === 'end_date') {
    const next2day = dayjs().add(2, 'day').format('DD/MM/YYYY');
    sampleData = next2day;
  } else if (
    field === 'interview_link' ||
    field === 'prescreen_link' ||
    field === 'candidate_link' ||
    field === 'slot_link' ||
    field === 'login_link'
  ) {
    sampleData = 'https://www.sample_link.com/';
  } else if (field === 'location' || field === 'place') {
    sampleData = 'Kochi';
  } else if (field === 'admin_name') {
    sampleData = 'Taylor Johnson';
  } else if (field === 'link_validity') {
    sampleData = '4 days';
  } else if (field === 'company_name') {
    sampleData = 'ABC Company';
  } else if (field === 'interview_round') {
    sampleData = 'First Round';
  } else if (field === 'start_time') {
    sampleData = '11:00 AM';
  } else if (field === 'end_time') {
    sampleData = '03:00 PM';
  } else if (field === 'username') {
    sampleData = 'JamieLee';
  } else if (field === 'password') {
    sampleData = 'JamieLee#123';
  } else if (field === 'hr_name') {
    sampleData = 'Casey Miller';
  } else if (field === 'candidate_name') {
    sampleData = 'Jordan Brown';
  } else if (field === 'interviewer_name') {
    sampleData = 'Morgan Davis';
  } else if (field === 'job_reject_reason') {
    sampleData = 'Not Satisfied';
  } else if (field === 'job_description') {
    sampleData =
      'Develop and implement new software programs using Java and mongo db.';
  } else if (field === 'interview_type') {
    sampleData = 'Permanant';
  } else if (field === 'consultancy_name') {
    sampleData = 'Talent Hr Consultancy';
  }
  return sampleData;
};

export const positiveIntegerValidation = (value) => {
  if (!value) return true; // Allow empty value (assuming it's not required)
  const intValue = parseInt(value, 10);
  return Number.isInteger(intValue) && intValue > 0;
};

export const getStringArray = (arr) => {
  return arr.join(', ');
};

export async function downloadFile(url, fileName) {
  const response = await axios.get(url, { responseType: 'blob' });

  const blob = response?.data;
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = blobUrl;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(blobUrl);
}

export function days_to_minutes(days) {
  return days * 24 * 60;
}

export function convertMinutesToDays(minutes) {
  const minutesInADay = 24 * 60;
  const days = minutes / minutesInADay;
  return days;
}

export function reOrderArray(array, fromIndex, toIndex) {
  const newArray = [...array];
  if (
    fromIndex < 0 ||
    fromIndex >= newArray.length ||
    toIndex < 0 ||
    toIndex >= newArray.length
  ) {
    throw new Error('Invalid index provided.');
  }
  const [movedObject] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, movedObject);
  return newArray;
}
